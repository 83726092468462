<template>
  <div class="PerformStep">
    <div class="content">
      <div class="introduce">
        <img
          class="introduce-pic"
          src="../../assets/image/履约保函.png"
          alt=""
        >
        <div class="introduce-text">
          <div class="text-tit">
            履约保函
          </div>
          <div class="text-detail">
            履约保函是指：应劳务方和承包方（申请人）的请求，银行金融机构向工程的业主方（招标人）做出的一种履约保证承诺。如果劳务方和承包方日后未能按时、按质、按量完成其所承建的工程，则银行将向业主方支付一笔约占合约金额5%~10%的款项。履约保函有一定的格式限制，也有一定的条件。
          </div>
          <a-button
            class="text-btn"
            type="primary"
            @click="$router.push({ path: '/performance', query: { type: '2' } })"
          >
            立即申请
          </a-button>
        </div>
        <!-- @click="$router.push({ path: '/performance', query: { code: '1' } })" -->
      </div>
      <div class="describe">
        <div class="describe-list">
          <div class="list-tit">
            一、产品定义：
          </div>
          <div class="list-text">
            履约保函是指：应劳务方和承包方（申请人）的请求，银行金融机构向工程的业主方（招标
            人）做出的一种履约保证承诺。如果劳务方和承包方日后未能按时、按质、按量完成其所承
            建的工程，则银行将向业主方支付保函约定款项，履约保函有一定的格式限制，也有一定的
            条件。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            二、担保方式承包商履约担保可采用的方式：
          </div>
          <div class="list-text">
            ① 银行保函；<br>
            ② 专业担保公司的保证、保险保函
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            三、担保额度:
          </div>
          <div class="list-text">
            承包商履约担保的担保金额一般为工程建设合同价格（中标价格）的5%-10%。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            四、担保期限:
          </div>
          <div class="list-text">
            承包商履约担保的有效期应当在保证合同中约定，保证合同预定的有效期截止时间为工程建
            设合同预定的工程竣工验收合格之日后的30天至180天，最长期限三年。
          </div>
        </div>
        <div class="describe-list">
          <div class="list-tit">
            五、客户申请提交资料清单：
          </div>
          <div class="list-text">
            1.企业基本资料：营业执照、财报报告、法人代表身份证等；2.工程资料：招标文件，中标
            通知书保函格式施工合同、项目要求的其他资料
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
};
</script>
<style scoped>
.content{
  width: 1500px;
  margin: 0 auto;
  padding: 30px 140px;
}
.introduce{
    display: flex;
    margin-bottom: 50px;
}
.introduce-text{
    margin-left: 30px;
}
.text-tit{
    font-size: 25px;
    font-weight: 800;
    margin-bottom: 5px;
}
.text-detail{
    line-height: 32px;
    margin-left: 5px;
}
.describe-list{
    margin-bottom: 50px;
}
.list-tit{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}
.list-text{
     font-size: 14px;
     line-height: 30px;
}
</style>
